


































import { NodeService } from '@/services/node.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LoopDownloadModal extends Vue {
    @Prop({ default: false }) show!: boolean;
    @Prop({}) loop!: any;
    @Prop({}) variant!: any;
    @Prop({}) pack!: any;

    get credits() {
        return this.$store.getters['USER__LIBRARY'].credits;
    }

    async download() {
        // check permissions
        const canDL = this.$store.getters['USER__CAN_DOWNLOAD_LOOPS'];

        if (!canDL) {
            return;
        }

        // get library id
        const libraryId = this.$store.getters['USER__LIBRARY']._id;
        // call node service
        const data = await NodeService.downloadLoop(libraryId, this.loop._id, this.variant._id, this.pack._id);

        const { url, success, message } = data;

        if (!success) {
            this.$store.dispatch('snackbar/pushError', { message: `There was an error: ${message}` });
            return;
        }

        window.open(url, '');
    }
}
