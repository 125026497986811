















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { KnoxService } from '@/services/knox.service';

import { StripeCheckout } from 'vue-stripe-checkout';

@Component({
    components: {
        StripeCheckout
    }
})
export default class CheckoutModal extends Vue {
    @Prop({ default: false }) show!: boolean;
    @Prop() pack!: any;

    loading = true;
    pk = '';
    session = '';

    get successUrl() {
        return `${window.location.href}?ck=true&cid={CHECKOUT_SESSION_ID}`
    }

    get cancelUrl() {
        return window.location.href;
    }

    async load() {
        this.loading = true;
        
        const { sessionId } = await KnoxService.createItemCheckoutSession(this.pack._id, this.$store.state.user.profile.stripeCustomer, this.successUrl, this.cancelUrl, 'pack', this.pack.priceId);
        
        this.session = sessionId;
        // request a payment intent
        // const intent = await KnoxService.createPaymentIntent(items);

        // request a price for the pack
        // const prices = await KnoxService.getItemPricing(items);
        
        this.loading = false;
    }

    mounted() {
        this.pk = process.env.VUE_APP_STRIPEPK;

        if (this.show) {
            this.load();
        }
    }

    @Watch('show')
    onShow(val: boolean, oldVal: boolean) {
        if (val) {
            this.load();
        }
    }
}
